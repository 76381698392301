import { styled } from '@mui/system';

export const Container = styled('div', { label: 'AuthFormContainer' })(({ theme }) => ({
  width: 360,
  maxWidth: '100%',
  padding: theme.spacing(6),
  display: 'flex',
  flexDirection: 'column',
  rowGap: theme.spacing(6),

  [theme.breakpoints.up('sm')]: {
    boxShadow: theme.shadows[3],
    borderRadius: theme.spacing(1),
  },
}));

export const Titles = styled('div', { label: 'AuthFormTitles' })(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  rowGap: theme.spacing(3),
}));

export const Form = styled('form', { label: 'AuthForm' })(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
  rowGap: theme.spacing(6),

  '& .MuiTextField-root': {
    margin: 0,
  },
}));

export const Actions = styled('div', { label: 'AuthFormActions' })(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  rowGap: theme.spacing(4),

  '& .MuiButton-root': {
    '& em': {
      color: theme.palette.primary.main,
      fontStyle: 'normal',
    },
  },
}));
