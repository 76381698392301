import { yupResolver } from '@hookform/resolvers/yup';
import { Button, TextField } from '@mui/material';
import Link from 'next/link';
import { useState, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { useLogin } from 'api';
import { Text, t } from 'components/formatted-message';
import { AUTH_ERRORS } from 'utils/constants';

import { AuthForm, AuthFormMessage, AuthLoader, AuthStatusCard } from './components';
import getErrorCodeContent from './get-error-code-content';

const schema = yup.object().shape({
  email: yup.string().email('invalid').required('required'),
  password: yup.string().required('required'),
});

function LoginTemplate() {
  const [isTotpStep, setIsTotpStep] = useState(false);
  const [{ message: errorMessage, icon: errorIcon }, setRequestError] = useState({
    message: null,
    icon: null,
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    watch,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handleError = useCallback(
    error => {
      const errorCode = error.response?.data?.code;
      const errorContent = getErrorCodeContent(errorCode);

      if (errorCode === AUTH_ERRORS.TOTP) {
        setRequestError({ message: '' });
        setIsTotpStep(true);
      } else if (errorContent) {
        setRequestError(errorContent);
        setError('email');
      } else if (errorCode === AUTH_ERRORS.WRONG_DATA) {
        setRequestError({ message: 'auth.login.error.wrong-data' });
        setError('email');
        setError('password');
      } else if (
        errorCode === AUTH_ERRORS.WRONG_TOTP ||
        error.response?.data?.errors?.totp_passcode
      ) {
        setRequestError({ message: 'auth.login.error.totp' });
        setError('totp_passcode');
      } else if (errorCode === AUTH_ERRORS.PASSWORD_EXPIRED) {
        setRequestError({ message: 'auth.login.error.password-expired' });
      } else {
        setRequestError({ message: 'auth.login.error.unknown' });
      }
    },
    [setError]
  );

  const { mutate: login, isLoading } = useLogin({ onError: handleError });

  if (isLoading) return <AuthLoader />;

  if (errorIcon) return <AuthStatusCard icon={errorIcon} label={errorMessage} />;

  return (
    <AuthForm.Container>
      <AuthForm.Titles>
        <Text align="center" id="auth.login.title" variant="h3" />

        {errorMessage && <AuthFormMessage id={errorMessage} error />}

        {isTotpStep && <AuthFormMessage id="auth.login.totp.hint" />}
      </AuthForm.Titles>

      <AuthForm.Form onSubmit={handleSubmit(login)}>
        {isTotpStep ? (
          <TextField
            {...register('totp_passcode')}
            autoComplete="one-time-code"
            error={Boolean(errors.totp_passcode)}
            helperText={
              errors.totp_passcode?.message &&
              t(`auth.login.totp.error.${errors.totp_passcode?.message}`)
            }
            label={t`auth.login.totp`}
            margin="normal"
            size="small"
            autoFocus
            fullWidth
          />
        ) : (
          <>
            <TextField
              {...register('email')}
              error={Boolean(errors.email)}
              helperText={
                errors.email?.message && t(`auth.login.email.error.${errors.email?.message}`)
              }
              label={t`auth.login.email.label`}
              margin="normal"
              size="small"
              fullWidth
            />
            <TextField
              {...register('password')}
              error={Boolean(errors.password)}
              helperText={
                errors.password?.message &&
                t(`auth.login.password.error.${errors.password?.message}`)
              }
              label={t`auth.login.password.label`}
              margin="normal"
              size="small"
              type="password"
              fullWidth
            />
          </>
        )}

        <AuthForm.Actions>
          <Button
            data-test-id="auth.login.submit"
            disabled={isTotpStep && watch().totp_passcode?.length === 0}
            type="submit"
            variant="contained"
            fullWidth>
            {t`auth.login.submit`}
          </Button>
          <Button
            component={Link}
            data-test-id="auth.login.reset-password"
            href="/password-reset"
            variant="text"
            fullWidth>
            {t`auth.login.reset-password`}
          </Button>
        </AuthForm.Actions>
      </AuthForm.Form>
    </AuthForm.Container>
  );
}

export default LoginTemplate;
