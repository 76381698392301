import { styled } from '@mui/system';
import PropTypes from 'prop-types';

import { Text } from 'components/formatted-message';

const StyledText = styled(Text, { label: 'AuthFormMessage' })(({ theme }) => ({
  '& em': {
    fontWeight: theme.typography.fontWeightBold,
    fontStyle: 'normal',
  },
}));

export function AuthFormMessage({ id, error }) {
  return (
    <StyledText
      align="center"
      color={error ? 'error' : 'textSecondary'}
      id={id}
      values={{ em: ([text]) => <em>{text}</em> }}
      variant="body2"
    />
  );
}

AuthFormMessage.propTypes = {
  id: PropTypes.string.isRequired,
  error: PropTypes.bool,
};

AuthFormMessage.defaultProps = {
  error: false,
};
