import AcUnit from '@mui/icons-material/AcUnit';
import Lock from '@mui/icons-material/Lock';
import Unsubscribe from '@mui/icons-material/Unsubscribe';

import { AUTH_ERRORS } from 'utils/constants';

const getErrorCodeContent = code => {
  switch (code) {
    case AUTH_ERRORS.INACTIVE:
      return { message: 'auth.error.inactive', icon: Unsubscribe };
    case AUTH_ERRORS.BLOCKED:
      return { message: 'auth.error.blocked', icon: Lock };
    case AUTH_ERRORS.FROZEN:
      return { message: 'auth.error.frozen', icon: AcUnit };
    default:
      return null;
  }
};

export default getErrorCodeContent;
