import { styled } from '@mui/system';
import PropTypes from 'prop-types';

import { Text } from 'components/formatted-message';

const Root = styled('div', { label: 'AuthStatusCard' })(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: 170,
  width: 360,
  maxWidth: '100%',
  padding: theme.spacing(6),

  [theme.breakpoints.down('xs')]: {
    position: 'absolute',
    top: '50%',
    transform: 'translate(0, -50%)',
  },

  [theme.breakpoints.up('sm')]: {
    boxShadow: theme.shadows[3],
    borderRadius: theme.spacing(1),
  },

  '& .MuiSvgIcon-root': {
    fontSize: 58,
    color: theme.palette.primary.main,
    marginBottom: theme.spacing(4),
  },

  '& .MuiTypography-root': {
    width: 215,
  },

  '& .MuiButton-root': {
    marginTop: theme.spacing(4),
  },
}));

export function AuthStatusCard({ children, icon: Icon, label }) {
  return (
    <Root>
      {Icon && <Icon />}

      <Text align="center" color="textSecondary" id={label} variant="subtitle1" />

      {children}
    </Root>
  );
}

AuthStatusCard.propTypes = {
  children: PropTypes.node,
  icon: PropTypes.elementType,
  label: PropTypes.string.isRequired,
};

AuthStatusCard.defaultProps = {
  children: null,
  icon: null,
};
