import { Paper, TextField } from '@mui/material';
import dynamic from 'next/dynamic';
import { PropTypes } from 'prop-types';

import { t } from 'components/formatted-message';
import { makeStyles } from 'utils/make-styles';

import { AuthFormMessage } from './auth-form-message';

const QRCodeSVG = dynamic(() => import('qrcode.react').then(mod => mod.QRCodeSVG));

const useStyles = makeStyles()(
  theme => ({
    text: {
      marginTop: theme.spacing(9),
      fontSize: '12px',
    },
    hint: {
      fontWeight: 'bold',
      marginTop: theme.spacing(2),
      maxWidth: '100%',
      overflowWrap: 'break-word',
    },
    qr: {
      marginBottom: theme.spacing(4),
    },
  }),
  { name: 'bo-TotpQR' }
);

export function TotpQR({ totpUri, register, isError }) {
  const { classes } = useStyles();
  const totpHint = totpUri.split('=').pop();
  return (
    <>
      <Paper
        sx={{
          p: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '100%',
        }}>
        <QRCodeSVG className={classes.qr} size={280} value={totpUri} />
        <AuthFormMessage className={classes.text} id="auth.totp.hint" />
        <span className={classes.hint}>{totpHint}</span>
      </Paper>
      <TextField
        {...register('totp')}
        autoComplete="one-time-code"
        error={Boolean(isError)}
        helperText={isError && t(`auth.password-update.totp.error.wrong`)}
        label={t`auth.totp.label`}
        margin="normal"
        size="small"
        autoFocus
        fullWidth
      />
    </>
  );
}

TotpQR.propTypes = {
  totpUri: PropTypes.string.isRequired,
  register: PropTypes.func.isRequired,
  isError: PropTypes.bool.isRequired,
};
