import { Container } from '@mui/material';
import PropTypes from 'prop-types';

import { Logo } from 'components/logo';
import { makeStyles } from 'utils/make-styles';

const useStyles = makeStyles()(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    minHeight: '100%',
    width: '100%',

    [theme.breakpoints.up('sm')]: {
      justifyContent: 'center',
    },
  },

  logo: {
    margin: theme.spacing(4, 0),

    [theme.breakpoints.up('sm')]: {
      position: 'absolute',
      top: 0,
    },
  },

  content: {
    margin: theme.spacing(16, 0, 8),
  },
}));

export function AuthLayout({ children }) {
  const { classes } = useStyles();

  return (
    <Container className={classes.root}>
      <Logo className={classes.logo} />

      <div className={classes.content}>{children}</div>
    </Container>
  );
}

AuthLayout.propTypes = {
  children: PropTypes.element.isRequired,
};

export const getLayout = page => <AuthLayout>{page}</AuthLayout>;
